<template>
    <div>
         <datatable :aanmeldingen='aanmeldingen'></datatable>
    </div>
</template>
<script>
import datatable from '@/components/beheer/Datatable.vue'

export default {
  name: 'vergaarbak',
  components: {
    datatable
  },
  mounted () {
    this.$store.dispatch('vergaarbak/getAanmeldingen', 2)
  },
  computed: {
    aanmeldingen: function () {
      return this.$store.state.vergaarbak.aanmeldingen
    }
  }
}
</script>
